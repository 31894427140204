<template>
  <v-container fluid class="px-0">
    <FAQPage />
  </v-container>
</template>

<script>
import FAQPage from "@/components/layout/others/FAQPage";
export default {
  name: "FAQ",
  components: { FAQPage }
};
</script>

<style scoped></style>
